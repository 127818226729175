h3 {
  margin: 0;
}

.side-select {
  width: 100%;
}

.side-select button {
  width: 50%;
}

.team-picked {
  visibility: hidden;
}

.bans {
  display: flex;
  flex-wrap: wrap;
}

.bans img {
  width: 4vw;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  margin: 8px;
  background-color: white;
  border: 1px dashed black;
  border-radius: 50%;
}

.red-bans, .blue-bans {
  display: flex;
}

.red-bans {
  margin-left: auto;
}

/* ----------------- */
.bottom-container {
  display: flex;
  margin-top: 3vh;
}

/* Picks */
.red-picks > div, .blue-picks > div {
  position: relative;
}

.red-picks img, .blue-picks img {
  width: 6.5vw;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  margin: 8px;
  background-color: white;
  border: 1px solid black;
}

.picked-name {
  position: absolute;
  z-index: 1;
  bottom: 9px;
  left: 9px;
  width: 6.4vw;
  background-color: #555555;
  color: white;
  text-align: center;
  font-size: 0.9vw;
}


/* Main table */
.main-table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  justify-items: center;
  width: 40%;
  margin: auto;
  background-color: white;
  overflow: auto;
}

.main-table div {
  border: 1px solid black;
  display: flex;
  width: 100%;
}

.main-table img {
  margin: auto;
  width: 5.25vw;
}

.main-table img:hover {
  cursor: pointer;
  opacity: .7;
}

.main-banned {
  background-color: #333333;
}

.main-banned img {
  filter: grayscale(100%);
}

.main-red-picked {
  background-color: rgb(255, 96, 96);
}

.main-blue-picked {
  background-color: rgb(118, 216, 255);
}

.currently-picking {
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(253, 255, 136);
  }
  to {
    box-shadow: 0 0 10px 10px rgb(253, 255, 136);
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
